import React from "react";
import "./Footer.css";
import logo from "./images/logo.png";
import pic1 from "./images/alex2.jpeg";
import pic2 from "./images/ashar2.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareInstagram,
  faSquareFacebook,
  faYoutube,
  faSquareWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="logo">
          <img src={logo} alt="logo" height={110}></img>
          <p>TRADE TUITION</p>
          <p>
            your premier destination for comprehensive share market coaching in
            Patna, Bihar. Specializing in Swing Trading, Personal Finance, and
            Equity Investing, we empower individuals with the knowledge and
            skills needed to navigate the dynamic world of financial markets.
          </p>

          <div id="buttons">
            <a href="https://www.instagram.com/trade.tuition/">
              <FontAwesomeIcon icon={faSquareInstagram} size={"2x"} />
            </a>
            <a href="https://www.facebook.com/Trade.tuition?mibextid=ZbWKwL">
              <FontAwesomeIcon icon={faSquareFacebook} size={"2x"} />
            </a>
            <a href="https://www.youtube.com/channel/UCBuNzZjKCIfXIXyCKMrRo5g">
              <FontAwesomeIcon icon={faYoutube} size={"2x"} />
            </a>
            <a href="https://wa.me/message/THG4II7YIAAHG1">
              <FontAwesomeIcon icon={faSquareWhatsapp} size={"2x"} />{" "}
            </a>
          </div>
        </div>

        <div className="contact">
          <p>Contact Info</p>
          <div className="contsub">
            <FontAwesomeIcon icon={faEnvelope} size={"1x"} />
            &nbsp;&nbsp;&nbsp;Tradetuition@gmail.com
            <br />
          </div>
          <div className="contsub">
            <FontAwesomeIcon icon={faPhone} size={"1x"} />{" "}
            &nbsp;&nbsp;9135943277
            <br />
          </div>
          <div className="address">
            <p>
              <FontAwesomeIcon icon={faLocationDot} />
              &nbsp; Address
            </p>
            <p>
              Pathan Toli, Alamganj
              <br />
              Gulzarbagh
              <br />
              Alamganj
              <br />
              Patna- 800007
            </p>
          </div>
        </div>

        <div className="links">
          <p>
            <u>QUICK LINKS</u>
          </p>
          <ul className="link">
            <li>
              <Link className="link" to="/">
                <p>Home</p>
              </Link>
            </li>
            <li>
              <Link className="link" to="/about">
                <p>About</p>
              </Link>
            </li>
            <li>
              <Link className="link" to="/courses">
                <p>Courses</p>
              </Link>
            </li>
            <li>
              <Link className="link" to="/login">
                <p>Login/Signup</p>
              </Link>
            </li>
          </ul>
        </div>

        <div className="photo">
          <p>
            <u>OUR TEAM</u>
          </p>
          <div class="image-container">
            <img src={pic2} alt="pic" height={150} class="pics"></img>
            <div class="overlay"></div>
          </div>
          <p>Ashar Alam</p>

          <div class="image-container">
            <img src={pic1} alt="pic" height={150} class="pics"></img>
            <div class="overlay"></div>
          </div>
          <p>Khizar Belal</p>
        </div>
      </div>

      <div className="developerinfo">
        © 2024. All Rights Reserved. | Designed & Managed By : Ankit Raj Meet
        <a href="https://www.instagram.com/ankit_raj_meet/">
          &nbsp;&nbsp;
          <FontAwesomeIcon icon={faSquareInstagram} />
        </a>
      </div>
    </div>
  );
}

export default Footer;
