import React, { useState } from "react";
import "./Nav.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "./images/logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import option from "./images/bars-solid.svg";
import closeBtn from "./images/angles-up-solid.svg";

function Nav() {
  const { user, loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const [showMenu, setShowMenu] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleUserCheck = async (userId, userName, email) => {
    try {
      const result = await axios.post(`${API_BASE_URL}/userExist.php`, {
        userId,
      });

      if (result.data.signup) {
        await axios.post(`${API_BASE_URL}/signupUser.php`, {
          userId,
          userName,
          email,
        });
        navigate("/phone");
      } else if (result.data.update) {
        // User exists and phone number is empty, update phone
        navigate("/phone");
      }
    } catch (error) {
      console.error("Error checking user existence:", error);
    }
  };

  return (
    <div className="navbar">
      <ul className="logopart">
        <li>
          <img src={logo} alt="logo" height={100}></img>
        </li>
        <li>TRADE TUITION</li>
      </ul>
      <div className="menuparts">
        <li>
          <Link className="link" to="/">
            Home
          </Link>
        </li>
        <li>
          <Link className="link" to="/about">
            About
          </Link>
        </li>
        <li>
          <Link className="link" to="/courses">
            Courses
          </Link>
        </li>
        {isAuthenticated ? (
          <>
            <li>
              <button className="link" id="logoutbtn" onClick={logout}>
                Logout
              </button>
            </li>
            <li>
              <img src={user.picture} alt="Profile" className="profile-pic" />
            </li>
          </>
        ) : (
          <li>
            <button
              className="link"
              id="loginbtn"
              onClick={() => {
                loginWithRedirect({
                  redirectUri: window.location.origin,
                  appState: {
                    targetUrl: "https://wa.me/message/THG4II7YIAAHG1",
                  },
                });
                if (isAuthenticated && user) {
                  handleUserCheck(user.sub, user.name, user.email);
                }
              }}
            >
              Login
            </button>
          </li>
        )}
      </div>

      <div className="menu-icon" onClick={toggleMenu}>
        <img src={option} height={35} alt="options"></img>
      </div>
      {showMenu && (
        <ul className="dropdown-menu">
          <li>
            <Link className="link" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="link" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="link" to="/courses">
              Courses
            </Link>
          </li>
          {isAuthenticated ? (
            <>
              <li>
                <button className="link" id="logoutbtn" onClick={logout}>
                  Logout
                </button>
              </li>
              <li>
                <img src={user.picture} alt="Profile" className="profile-pic" />
              </li>
            </>
          ) : (
            <li>
              <button
                className="link"
                id="loginbtn"
                onClick={() => {
                  loginWithRedirect({
                    redirectUri: window.location.origin,
                    appState: {
                      targetUrl: "https://wa.me/message/THG4II7YIAAHG1",
                    },
                  });
                  if (isAuthenticated && user) {
                    handleUserCheck(user.sub, user.name, user.email);
                  }
                }}
              >
                Login
              </button>
            </li>
          )}
          <li>
            <div className="menu-icon" onClick={toggleMenu} id="closeBtn">
              <img src={closeBtn} height={35} alt="options"></img>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Nav;
