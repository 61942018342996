import React, { useState, useEffect } from "react";
import "./About.css";
import pic1 from "./images/stock1.avif";
import pic2 from "./images/img9.gif";
import pic4 from "./images/sack-dollar-solid.svg";
import pic5 from "./images/comment-solid.svg";
import pic6 from "./images/book-solid.svg";
import pic7 from "./images/graduation-cap-solid.svg";
import pic8 from "./images/gears-solid.svg";
import pic9 from "./images/chalkboard-solid.svg";
import pic11 from "./images/frame.gif";
import pic12 from "./images/alex2.jpeg";
import pic13 from "./images/ashar2.jpeg";

function About() {
  const [activeIndex, setActiveIndex] = useState(-1);
  useEffect(() => {
    const handleScroll = () => {
      const card = document.querySelectorAll(".card");
      let found = false;
      card.forEach((card, index) => {
        const rect = card.getBoundingClientRect();
        if (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
          setActiveIndex(index);
          found = true;
        }
      });

      if (!found) {
        setActiveIndex(-1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="body">
      <div className="header">
        <div id="pic1">
          <img src={pic1} alt="pic"></img>{" "}
        </div>
        <div className="aboutus">
          <div className="abtusprt1">
            <img src={pic2} height={150} alt="pic"></img>
            <div className="abtheading">ABOUT US</div>
          </div>
          <div>
            <p> " Welcome to Trade Tuition "</p>
            <p>
              your premier destination for comprehensive share market coaching
              in Patna, Bihar. Specializing in Swing Trading, Personal Finance,
              and Equity Investing, we empower individuals with the knowledge
              and skills needed to navigate the dynamic world of financial
              markets.
            </p>
            <p>
              At Trade Tuition, we offer a diverse range of learning options to
              suit your preferences and schedule. Dive into our expertly crafted
              Pre-recorded Courses for self-paced learning, join our engaging
              Live Online Classes for real-time interaction, or attend Offline
              Classes at our Patna Centre for a personalized touch.
            </p>
            <p>
              Our commitment goes beyond just teaching the intricacies of the
              market; we strive to foster a community of informed and confident
              investors & Traders. Join Trade Tuition today and embark on a
              journey towards financial mastery in the exciting realm of share
              trading and investment.
            </p>
          </div>
          <p id="whyus">WHY TO CHOOSE US?</p>
         
        </div>
      </div>

      <div className="cardcontainer">
        <div
          className={`card ${
            activeIndex >= 0 && activeIndex >= 0 ? "active" : ""
          }`}
        >
          <img src={pic4} height={70} className="cardimg" alt="pic"></img>
          <p>LEARN WITH US ON AFFORDABLE PRICE RANGE</p>
          <div class="sliding-window"></div>
        </div>
        <div
          className={`card ${
            activeIndex >= 1 && activeIndex >= 1 ? "active" : ""
          }`}
        >
          <img src={pic5} height={70} className="cardimg" alt="pic"></img>
          <p>ONE ON ONE SUPPORT &GUIDANCE AFTER COURSE COMPLETION</p>
          <div class="sliding-window"></div>
        </div>
        <div
          className={`card ${
            activeIndex >= 2 && activeIndex >= 2 ? "active" : ""
          }`}
        >
          <img src={pic6} height={70} className="cardimg" alt="pic"></img>
          <p>FREE NOTES AVAILABLE FOR PROPER LEARNING </p>
          <div class="sliding-window"></div>
        </div>
        <div
          className={`card ${
            activeIndex >= 3 && activeIndex >= 3 ? "active" : ""
          }`}
        >
          <img src={pic7} height={70} className="cardimg" alt="pic"></img>
          <p>WELL EXPERIENCED TEAM</p>
          <div class="sliding-window"></div>
        </div>
        <div
          className={`card ${
            activeIndex >= 4 && activeIndex >= 4 ? "active" : ""
          }`}
        >
          <img src={pic8} height={70} className="cardimg" alt="pic"></img>
          <p>UPDATED COURSE AS MARKET AND STRATIGIES UPDATES</p>
          <div class="sliding-window"></div>
        </div>
        <div
          className={`card ${
            activeIndex >= 5 && activeIndex >= 5 ? "active" : ""
          }`}
        >
          <img src={pic9} height={70} alt="pic" className="cardimg"></img>
          <p>LEARN ONLINE OR OFFLINE </p>
          <div class="sliding-window"></div>
        </div>
      </div>

      <div className="mentor">
        <div className="mentorheading">
          <p id="meetmentor">FROM THE PEN OF YOUR MENTOR</p>
        </div>

        <div id="alex">
          <div className="profileBox">
            <img src={pic11} alt="pic" height={250} className="frame"></img>
            <img src={pic12} alt="pic" height={160} id="alexdp"></img>
          </div>

          <div id="alexdiscription">
            <p id="name1">Khizar Belal</p>
            <p className="disc">
              A seasoned professional with a Master's in Computer Application.
              <br></br> With over 7 years of teaching experience, I found my
              passion in guiding and inspiring others. Beyond the classroom,I am
              an avid Equity Investor,achieved multibagger returns in various
              companies. My passion for both teaching and financial market
              drives my commitment to empower others in their educational and
              investment journeys.
              <br />
              Join our classes to become pro in equity investing and personal
              finance
            </p>
          </div>
        </div>

        <div id="ashar">
          <div id="ashardiscription">
            <p id="name2">Ashar Alam</p>
            <p className="disc">
              Are you ready to learn the ropes of trading from a seasoned
              professional?
              <br />
              Look no further, as Ashar Alam ,your distinguished trading teacher
              ,brings over 7 years of rich experiencein the dynamic world of
              financial markets.
              <br />
              Throughout my career, I have achieved notable success, both as a
              trader and a mentor. My ability to simplify complex
              concepts,coupled with my dedication to student success,has earned
              me accolades within the trading community. Many of my student have
              gone on to achieve significant milestones in their trading
              journeys under my guidance.
              <br />
              Join our class to Embark on your trading journey with confidence
              under guidance of me Ashar Alam. Weather you are a beginner
              looking to grasp the basics or an experienced trader seeking to
              refine your skills, My expertise and proven track record make me
              the ideal mentor for your trading aspirations.
              <br />
              Enroll in Trade Tuition today and experience firsthand the
              transformative power of Ashar Alam's insights and teachings. Let's
              unlock the doors to financial success together.
            </p>
          </div>

          <div className="profileBox" id="profileBox2">
            <img src={pic11} alt="pic" height={250} className="frame"></img>
            <img src={pic13} alt="pic" height={150} id="alexdp"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
