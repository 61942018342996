import "./App.css";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { Outlet ,useNavigate} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";


function App() {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    window.location.href = appState?.targetUrl;
    navigate("/");
  };

  return (
    <div className="App">
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <Nav />
        <Outlet />
        <Footer />
      </Auth0Provider>
    </div>
  );
}
export default App;
