import React from 'react'
import Main from './Main'
function Home() {
  return (
    <div>
      
      <Main></Main>
     
    </div>
  )
}

export default Home
