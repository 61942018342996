import React from "react";
import "./Card.css";
import GlassButton from './GlassButton'

function Card(props) {
  
  return (
    <div className="outer">
      <div>
        <img id="courseImg" src={props.banner} alt="pic"></img>
      </div>

      <p id="CourseTitle">{props.title}</p>
      <div class="sliding-window"></div>
      <div id="priceSect">
        <p>{props.tutor}</p>
       
          <GlassButton>ENROLL NOW</GlassButton>
        
        <p>{props.price}</p>
      </div>
    </div>
  );
}

export default Card;
