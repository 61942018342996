import React, { useState } from "react";
import './Phone.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
const Phone = () => {
  const { user} = useAuth0();
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const handlePhoneSubmit = async (event) => {
    event.preventDefault();
    try {
      
      await axios.post(`${API_BASE_URL}/updateUserPhone.php`, {
        userId: user.sub,
        phone,
      });
      navigate('/')
     
      
    } catch (error) {
      console.error("Error updating phone number:", error);
    }
  };

  return (
    <div className="PhoneForm">
      <h2>Enter Your Mobile Number</h2>
      <form onSubmit={handlePhoneSubmit}>
        <input
          type="text"
          
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Mobile Number"
          required
        />
        <br></br>
        
        <button type="submit"><b>SAVE</b></button>
      </form>
    </div>
  );
};

export default Phone;
