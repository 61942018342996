import React from "react";
import "./Main.css";
import pic from "./images/mainpic.png";
import About from "./About";
import Topics from "./Topics";
import GlassButton from "./GlassButton";
import Courses from "./Courses"
function Main() {
  return (
    <div className="main">
      <div className="banner">
        <div className="slogan">
          <div id="slg1">LEARN STOCK MARKET WITH</div>
          <div className="waviy">
            <span style={{ "--i": "1" }}>T</span>
            <span style={{ "--i": "2" }}>R</span>
            <span style={{ "--i": "3" }}>A</span>
            <span style={{ "--i": "4" }}>D</span>
            <span style={{ "--i": "5" }}>E</span>&nbsp;&nbsp;&nbsp;
            <span style={{ "--i": "6" }}>T</span>
            <span style={{ "--i": "7" }}>U</span>
            <span style={{ "--i": "8" }}>I</span>
            <span style={{ "--i": "9" }}>T</span>
            <span style={{ "--i": "10" }}>I</span>
            <span style={{ "--i": "11" }}>O</span>
            <span style={{ "--i": "12" }}>N</span>
          </div>
          <div id="slg3">Let's become financially Independent..</div>
          
            <GlassButton>REGISTER NOW</GlassButton>
          
        </div>
        <div className="picures">
          <img src={pic} alt="" height={400} />
        </div>
      </div>
      <Topics></Topics>
      <Courses></Courses>
      <About></About>
    </div>
  );
}

export default Main;
