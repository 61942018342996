import React, { useState, useEffect } from "react";
import "./Topics.css";
import pic1 from "./images/house.svg";
import pic2 from "./images/money-bill-trend-up-solid.svg";
import pic3 from "./images/handshake-solid.svg";
import pic4 from "./images/sack-dollar-solid.svg";
import pic5 from "./images/brain-solid.svg";
import pic6 from "./images/piggy-bank-solid.svg";
import GlassButton from "./GlassButton";

const Topics = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    const handleScroll = () => {
      const circles = document.querySelectorAll(".circle");
      let found = false;
      circles.forEach((circle, index) => {
        const rect = circle.getBoundingClientRect();
        if (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
          setActiveIndex(index);
          found = true;
        }
      });

      if (!found) {
        setActiveIndex(-1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="topics">
      <div
        id="heading"
        className={`circle ${
          activeIndex >= 0 && activeIndex >= 0 ? "active" : ""
        }`}
      >
        <p>What are you going to learn?</p>
      </div>

      <div className="circle-container">
        <div
          className={`circle ${
            activeIndex >= 0 && activeIndex >= 0 ? "active" : ""
          }`}
        >
          <img src={pic1} height={40} alt="pic"></img>
          <p>Stock Market Foundation</p>
          <p className="description">
            It doesn't matter whether you want to trade or invest; understanding
            the stock market foundation is something that everyone should know.
            Here, we'll learn all the basics of the stock market.
          </p>
          <div class="sliding-window"></div>

          <GlassButton>ENROLL NOW</GlassButton>
        </div>
        <div
          className={`circle ${
            activeIndex >= 1 && activeIndex >= 1 ? "active" : ""
          }`}
        >
          <img src={pic2} height={40} alt="pic"></img>
          <p>Swing Trading</p>
          <p className="description">
            Swing trading involves holding stocks for short to medium-term
            periods, capitalizing on price "swings" or fluctuations within a
            trend.
          </p>
          <div class="sliding-window"></div>

          <GlassButton>ENROLL NOW</GlassButton>
        </div>
        <div
          className={`circle ${
            activeIndex >= 2 && activeIndex >= 2 ? "active" : ""
          }`}
        >
          <img src={pic3} height={40} alt="pic"></img>
          <p>Equity Investing</p>
          <p className="description">
            Do You Want Better Returns Than Mutual Funds? Well, You're at the
            right place. In this course, you'll learn Everything about equity
            investing. You're Another Source of Passive Income.
          </p>
          <div class="sliding-window"></div>

          <GlassButton>ENROLL NOW</GlassButton>
        </div>
      </div>

      <div className="circle-container">
        <div
          className={`circle ${
            activeIndex >= 3 && activeIndex >= 3 ? "active" : ""
          }`}
        >
          <img src={pic4} height={40} alt="pic"></img>
          <p>Positional Trading</p>
          <p className="description">
            Positional trading involves holding trading positions for a longer
            duration, typically from weeks to months, based on fundamental
            analysis.
          </p>
          <div class="sliding-window"></div>

          <GlassButton>ENROLL NOW</GlassButton>
        </div>
        <div
          className={`circle ${
            activeIndex >= 4 && activeIndex >= 4 ? "active" : ""
          }`}
        >
          <img src={pic5} height={40} alt="pic"></img>
          <p>Market Psychology</p>
          <p className="description">
            The stock market is a long journey, and without right mindset, you
            may not survive much longer. That's why we focus on building the
            right psychology.
          </p>
          <div class="sliding-window"></div>

          <GlassButton>ENROLL NOW</GlassButton>
        </div>
        <div
          className={`circle ${
            activeIndex >= 5 && activeIndex >= 5 ? "active" : ""
          }`}
        >
          <img src={pic6} height={40} alt="pic"></img>
          <p>Personal Finance</p>
          <p className="description">
            Everyone wants to become financially free, but that's not possible
            without understanding personal finance. Let's learn for a stable
            future.
          </p>
          <div class="sliding-window"></div>

          <GlassButton>ENROLL NOW</GlassButton>
        </div>
      </div>
    </div>
  );
};

export default Topics;
