import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Route} from 'react-router-dom'
import App from './App';
import Home from './components/Home';
import About from './components/About';
import Courses from './components/Courses';
import Phone from './components/Phone'

import reportWebVitals from './reportWebVitals';
import { RouterProvider,createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
 const router=createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App/>}>
      <Route path='' element={<Home/>}></Route>
      <Route path='about' element={<About/>}/>
      <Route path='courses' element={<Courses/>}/>
      <Route path='phone' element={<Phone/>}/>
    </Route>
  )
 )

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);


reportWebVitals();
