import React, { useState, useEffect } from "react";
import Card from "./Card";
import "./Courses.css";

function Courses() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_COURSES,
        {
          
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch courses");
      }

      const data = await response.json();

      setCourses(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="courseContainer">
      <div id="enroll">
        <p>OUR COURSES</p>
      </div>

      {courses.map((course, index) => (
        <Card
          key={index}
          title={course.CourseTitle}
          price={course.Price}
          tutor={course.Tutor}
          banner={course.banner}
        />
      ))}
    </div>
  );
}

export default Courses;
