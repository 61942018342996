import React, { useEffect } from "react";
import "./GlassButton.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const GlassButton = ({ children }) => {
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleUserCheck = async (userId, userName, email) => {
    try {
      const result = await axios.post(`${API_BASE_URL}/userExist.php`, {
        userId,
      });
     
      if (result.data.signup) {
        // User does not exist, signup
        await axios.post(`${API_BASE_URL}/signupUser.php`, {
          userId,
          userName,
          email,
        });
        navigate("/phone");
      } else if (result.data.update) {
       
        navigate("/phone");
      } 
    } catch (error) {
      console.error("Error checking user existence:", error);
    }
  };

  
  useEffect(() => {
    if (isAuthenticated && user) {
      handleUserCheck(user.sub, user.name, user.email);
    }
  }, [isAuthenticated, user]);

  const handleButtonClick = async () => {
    if (!isAuthenticated) {
      await loginWithRedirect({
        redirectUri: window.location.origin,
        appState: {
          targetUrl: "https://wa.me/message/THG4II7YIAAHG1",
        },
      });
      navigate("/")
    }
    else {
      window.location.href = "https://wa.me/message/THG4II7YIAAHG1";
      navigate("/")
    }
  };

  return (
    <div className="glassButton" onClick={handleButtonClick}>
      {children}
    </div>
  );
};

export default GlassButton;
